<template>
  <section class="sub-page iframe">
    <top-section
      :navs="navs"
      :title="nav_title"
    />

    <b-row
      v-if="!iframe.loaded"
      cols="3"
      class="p-3 mx-auto col-md-10"
    >
      <b-col
        v-for="(i, index) in 9"
        :key="index"
        class="p-3"
      >
        <content-placeholders :rounded="true">
          <content-placeholders-img style="height: 350px;" />
          <content-placeholders-heading />
        </content-placeholders>
      </b-col>
    </b-row>

    <iframe
      v-show="iframe.loaded" 
      id="nmrc__news" 
      ref="nmrc__news-frame"
      :src="iframe.src"
      scrolling="no"
      style="width:100%; min-height:1950px; margin-top:-440px; border:0;"
      @load="load"
    />
  </section>
</template>

<script>
import TopSection from '../components/General/TopSection.vue'

export default {
    name: 'SurveyOwnAHome',
    components: { TopSection },

    data: () => ({
        nav_title: 'Housing Statistics Dashboard',
        navs: [
            {
                route_name: 'home',
                route_title: 'Home',
            },
            {
                route_name: 'housing-price',
                route_title: 'Housing Price',
                active: true,
            },
        ],
        visible: true,
        iframe: {
            src: 'https://app.powerbi.com/view?r=eyJrIjoiNWVhNTM4OTItNGMwNS00MjIwLWI5ZWUtYWQ4MzQxNmMwNTAyIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9',
            loaded: false
        }
    }),

    methods: {
      load() {
        this.iframe.loaded = true;
      },
    },
}
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
</style>

