<template>
  <div
    class="page-header mt-30 mb-40"
    data-midnight="white"
  >
    <div class="flex mb-10">
      <div class="relative">
        <div class="w-full top-0">
          <img
            src="/theme-assets/images/heroSectionImage.png"
            alt=""
            class="h-[400px] md:h-[324px] w-full"
          >
        </div>
        <div class="absolute top-20 left-[15%] md:left-[25%] right-[15%] md:right-[25%] text-center">
          <div class="mt-20 text-center">
            <p class="text-white text-4xl">
              {{ title }}
            </p>
            <div class="breadcrumb">
              <span
                v-for="(item, index) in navs"
                :key="index"
              >
                <router-link
                  :to="{ name: item.route_name }"
                  class="text-capitalize"
                  :class="{ 'current': item.active }"
                >
                  {{ item.route_title }}
                </router-link>
                <span v-show="!isLastStep(index)">/</span>
              </span>
            </div>
            <!-- <p class="text-white pt-2">
              Home / Dashboards / Nigerian Real Estate Data Collation Management Program Dashboard
            </p> -->
          </div>
  
          <!-- <div class="text-center py-4">
            <select
              id="fruit"
              name="fruit"
              class="w-4/6  rounded-lg p-2"
            >
              <option value="dashboard">
                <a href="./dashboard.html">Dashboards</a>
              </option>
              <option value="mortgageboard">
                <a href="./MortgageBoard.html">HMIP Mortgage statistics board</a>
              </option>
              <option value="housingPriceIndex">
                <a href="./HousingPriceIndex.html">Housing Price Index Dashboard</a>
              </option>
              <option value="realEstate">
                <a href="./RealEstate.html">Nigerian Real Estate Data Collation Management Program Dashboard</a>
              </option>
            </select>
          </div> -->
        </div>
      </div>
    </div>
  </div>
<!--     
  <div
    class="page-header mt-30"
    data-midnight="white"
  >
    <div class="img" />
    <div class="head-content container-fluid">
      <div class="container">
        <h1 class="page-title text-capitalize mt-10">
          {{ title }}
        </h1>
        <div class="breadcrumb">
          <span
            v-for="(item, index) in navs"
            :key="index"
          >
            <router-link
              :to="{ name: item.route_name }"
              class="text-capitalize"
              :class="{ 'current': item.active }"
            >
              {{ item.route_title }}
            </router-link>
            <span v-show="!isLastStep(index)">/</span>
          </span>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
    name: 'TopSection',

    props: {
        title: {
            type: String,
            default: '',
        },
        navs: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
      isLastStep(index) {
        return this.navs.length === index+1;
      },
  },
}
</script>